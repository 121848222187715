import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import qs from 'query-string'
import Header from '../components/ExternalPagesHeader'
import { Box } from '@chakra-ui/core'

// require('dotenv').config()

const URL = `${process.env.GATSBY_IFRAME_PROTOCOL}://${process.env.GATSBY_IFRAME_URL}`

const ConsultaReceita = () => {
	// Adriano pediu para passar para o endereco dele dessa maneira
	// exemplos:
	// https://bpm-hom.receitamedica.com.vc/consulta-receita/farmaceutico/999
	// https://bpm-hom.receitamedica.com.vc/consulta-receita/paciente/999
	// sendo 999 o id do paciente

	let signature
	let id

	if (typeof window !== 'undefined') {
		const query = qs.parse(window.location.search)
		if ('id' in query) {
			id = query.id as string
		}

		if ('signature' in query) {
			signature = query.signature as string
		}
	}

	return (
		<Layout>
			<SEO title='Consultar receita' />
			{/* <HeaderIframe /> */}
			<Header />

			{id && signature ? (
				<Box
					as='iframe'
					w='100%'
					h='calc(100vh - 72px)'
					id='iframe-assinatura-digital'
					frameBorder='0'
					title='Iframe de Assinatura Digital'
					src={`${URL}/consulta-receita/${id}?signature=${signature}`}
					allowFullScreen
				/>
			) : (
				<div />
			)}
		</Layout>
	)
}

export default ConsultaReceita
